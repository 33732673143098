import { ShopOrder, ShopLineItem } from '@mindfulchefuk/api-client'
import { ISODate } from '@mindfulchefuk/lib/toISODateFormat'
import errorHandler from '@mindfulchefuk/utils/errorHandler'
import { transformLineItem } from '@mindfulchefuk/query/ShopOrders/transforms'

export interface CreateShopLineItemParams {
  orderId: string
  productId: string
  quantity: number
  deliveryDate: ISODate
}

export const createShopLineItem = async ({
  orderId,
  productId,
  quantity,
  deliveryDate,
}: CreateShopLineItemParams) => {
  try {
    const order = new ShopOrder({ id: orderId })

    order.isPersisted = true
    order.delivery_date = deliveryDate

    const lineItem = new ShopLineItem({
      product_id: productId,
      quantity,
      order,
    })

    const success = await lineItem.save({
      with: 'order',
    })

    if (!success) {
      const error = 'Line items failed to create'
      errorHandler(new Error(error))
      throw new Error(error)
    }

    return transformLineItem(lineItem)
  } catch (error) {
    errorHandler(error)
  }
}
