import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ShopOrder } from '@mindfulchefuk/query/ShopOrders/interfaces'
import { deleteShopLineItem } from '@mindfulchefuk/query/ShopOrders/services'

export const useMutationDeleteShopLineItem = (customerId: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: deleteShopLineItem,
    /** Optimistically remove the item from the query cache straight away */
    onError: () => queryClient.invalidateQueries({ queryKey: ['shop-orders'] }),
    onMutate: async (lineItem) => {
      await queryClient.cancelQueries({
        queryKey: ['shop-orders', 'basket', { customerId }],
      })

      queryClient.setQueryData<ShopOrder>(
        ['shop-orders', 'basket', { customerId }],
        (previous) => {
          if (!previous) return

          const lineItems = previous.lineItems.filter(
            (item) => item.lineItemId !== lineItem.lineItemId
          )

          return { ...previous, lineItems }
        }
      )
    },
  })
}
