import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ShopOrder } from '@mindfulchefuk/query/ShopOrders/interfaces'
import {
  createShopLineItem,
  CreateShopLineItemParams,
} from '@mindfulchefuk/query/ShopOrders/services'

interface useMutationCreateShopLineItemParams extends CreateShopLineItemParams {
  unitPrice: number
}

export const useMutationCreateShopLineItem = (customerId: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: useMutationCreateShopLineItemParams) =>
      createShopLineItem(params),
    /** Optimistically add the lineItem to the query cache straight away */
    onMutate: async ({ productId, quantity, unitPrice }) => {
      await queryClient.cancelQueries({
        queryKey: ['shop-orders', 'basket', { customerId }],
      })

      queryClient.setQueryData<ShopOrder>(
        ['shop-orders', 'basket', { customerId }],
        (previous) => {
          return {
            ...previous,
            lineItems: [
              ...previous.lineItems,
              { productId, quantity, unitPrice },
            ],
          }
        }
      )
    },
    /** Add the lineItemId and unitPrice (from the mutation response) to the lineItem in the query cache  */
    onSuccess: async ({ lineItemId, productId, unitPrice }) => {
      queryClient.setQueryData<ShopOrder>(
        ['shop-orders', 'basket', { customerId }],
        (previous) => {
          const lineItems = previous.lineItems.map((item) =>
            item.productId === productId
              ? { ...item, lineItemId, unitPrice }
              : item
          )

          return { ...previous, lineItems }
        }
      )
    },
    onError: () => queryClient.invalidateQueries({ queryKey: ['shop-orders'] }),
  })
}
