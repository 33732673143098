import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { Customer } from '@mindfulchefuk/query/Customer/interfaces'
import { getCustomer } from '@mindfulchefuk/query/Customer/services'
import { STALE_15_MINS } from '@mindfulchefuk/constants'

export const useQueryCustomer = <TData = Customer>(
  customerId: string,
  options: UseQueryOptions<Customer, unknown, TData> = {}
) => {
  return useQuery({
    queryKey: ['customer', { customerId }],
    queryFn: () => getCustomer(customerId),
    enabled: !!customerId,
    staleTime: STALE_15_MINS,
    ...options,
  })
}
