import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  PendingLineItem,
  ShopOrder,
} from '@mindfulchefuk/query/ShopOrders/interfaces'
import { updateShopOrder } from '@mindfulchefuk/query/ShopOrders/services'

export const useMutationUpdateShopOrder = (customerId: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ basketOrder, lineItems }: MutationParams) =>
      updateShopOrder({
        basketOrder,
        lineItems,
      }),
    onSuccess: (newOrder) => {
      queryClient.setQueryData(
        ['shop-orders', 'basket', { customerId }],
        newOrder
      )
    },
  })
}

type MutationParams = {
  basketOrder: ShopOrder
  lineItems: PendingLineItem[]
}
