import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createShopOrder } from '@mindfulchefuk/query/ShopOrders/services'
import {
  ShopOrder,
  ShopOrderStatus,
} from '@mindfulchefuk/query/ShopOrders/interfaces'
import { ApiError } from '@mindfulchefuk/utils/spraypaintUtils'
import { ErrorName } from '@mindfulchefuk/analytics'
import { instrumentErrorEncountered } from '@mindfulchefuk/utils/analytics/amplitude/instrument/instrumentErrorEncountered'

interface Params {
  customerId: string
  deliveryAddressId: string
  deliveryDate: string
}

type PendingLineItem = {
  quantity: number
  productId: string
}

export const useMutationCreateShopOrder = ({
  customerId,
  deliveryAddressId,
  deliveryDate,
}: Params) => {
  const queryClient = useQueryClient()

  return useMutation<ShopOrder, unknown, PendingLineItem[]>({
    mutationFn: (lineItems: PendingLineItem[]) =>
      createShopOrder({
        customerId,
        deliveryAddressId,
        deliveryDate,
        lineItems,
      }),
    // Add the orderId to the query cache straight away with a TEMP orderId
    onMutate: async (lineItems) => {
      await queryClient.cancelQueries({
        queryKey: ['shop-orders', 'basket', { customerId }],
      })

      queryClient.setQueryData<ShopOrder>(
        ['shop-orders', 'basket', { customerId }],
        {
          orderId: 'TEMP',
          lineItems,
          deliveryDate,
          status: ShopOrderStatus.BASKET,
          shippingPrice: 0,
        }
      )
    },
    onError: async (err) => {
      if (err instanceof ApiError) {
        instrumentErrorEncountered({
          errorCode: err.httpStatus,
          errorType: 'create ready to go order',
          errorName: err.code as ErrorName,
        })
      }

      await queryClient.invalidateQueries({ queryKey: ['shop-orders'] })
    },
    onSuccess: (newOrder) => {
      queryClient.setQueryData(
        ['shop-orders', 'basket', { customerId }],
        newOrder
      )
    },
  })
}
