import { ShopLineItem } from '@mindfulchefuk/api-client'
import errorHandler from '@mindfulchefuk/utils/errorHandler'

interface DeleteShopLineItemParams {
  lineItemId: string
}

export const deleteShopLineItem = async ({
  lineItemId,
}: DeleteShopLineItemParams) => {
  try {
    const lineItem = new ShopLineItem({ id: lineItemId })

    lineItem.isMarkedForDestruction = true

    await lineItem.destroy()
  } catch (error) {
    errorHandler(error)
  }
}
