import { analyticsEvents, ErrorType, ErrorName } from '@mindfulchefuk/analytics'

import { instrument } from '@mindfulchefuk/utils/analytics/amplitude/amplitudeAdapter'

interface Error {
  errorType: ErrorType
  errorName: ErrorName
  errorCode: number
}

export const instrumentErrorEncountered = ({
  errorType,
  errorName,
  errorCode,
}: Error) => {
  const { name, data } = analyticsEvents.errorEncountered({
    errorType,
    errorName,
    errorCode,
  })

  instrument(name, data)
}
