import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ShopOrder } from '@mindfulchefuk/query/ShopOrders/interfaces'
import { updateShopLineItem } from '@mindfulchefuk/query/ShopOrders/services'

export const useMutationUpdateShopLineItem = (customerId: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: updateShopLineItem,
    /** Optimistically update the lineItem in the query cache straight away */
    onMutate: async (lineItem) => {
      await queryClient.cancelQueries({
        queryKey: ['shop-orders', 'basket', { customerId }],
      })
      queryClient.setQueryData<ShopOrder>(
        ['shop-orders', 'basket', { customerId }],
        (old) => {
          if (!old) return

          const lineItems = old.lineItems.map((item) => {
            if (item.lineItemId === lineItem.lineItemId) {
              return { ...item, quantity: lineItem.quantity }
            }
            return item
          })

          return {
            ...old,
            lineItems,
          }
        }
      )
    },
    onError: () => queryClient.invalidateQueries({ queryKey: ['shop-orders'] }),
  })
}
