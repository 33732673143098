import {
  Product,
  ProductStatus,
  ProductWithQuantity,
  ProductType,
} from '@mindfulchefuk/query/Products/interfaces'
import type { LineItem } from '@mindfulchefuk/query/ShopOrders/interfaces'

export const transformProductWithQuantity = (
  products: Product[],
  type: ProductType,
  lineItems?: LineItem[]
): ProductWithQuantity[] =>
  products
    .filter((product) => {
      if (type === 'bundled_products') {
        return (
          (product.isBundle || product.productType === 'bundled_products') &&
          product.status === ProductStatus.PUBLISHED
        )
      }
      return (
        product.productType === type &&
        product.status === ProductStatus.PUBLISHED
      )
    })
    .map((product) => {
      const lineItemForProduct = lineItems?.find(
        (lineItem) => lineItem.productId === product.id
      )

      return {
        ...product,
        quantity: lineItemForProduct?.quantity || 0,
        price: lineItemForProduct?.unitPrice || product.price,
      }
    })
    .sort((a, b) => Number(b.inStock) - Number(a.inStock))
