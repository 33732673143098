import { ShopOrder, ShopLineItem } from '@mindfulchefuk/api-client'
import { ISODate } from '@mindfulchefuk/lib/toISODateFormat'
import errorHandler from '@mindfulchefuk/utils/errorHandler'
import { APILineItem } from '@mindfulchefuk/query/ShopOrders/interfaces'

interface UpdateShopLineItemParams {
  orderId: string
  lineItemId: string
  quantity: number
  deliveryDate: ISODate
}

export const updateShopLineItem = async ({
  orderId,
  lineItemId,
  quantity,
  deliveryDate,
}: UpdateShopLineItemParams): Promise<APILineItem> => {
  try {
    const order = new ShopOrder({
      id: orderId,
    })

    order.isPersisted = true
    order.delivery_date = deliveryDate

    const lineItem = new ShopLineItem({
      id: lineItemId,
      order,
    })

    lineItem.isPersisted = true
    lineItem.quantity = quantity

    const success = await lineItem.save({
      with: 'order',
    })

    if (!success) {
      const error = 'Line items failed to update'
      errorHandler(new Error(error))
      throw new Error(error)
    }

    return lineItem
  } catch (error) {
    errorHandler(error)
  }
}
