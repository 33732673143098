import { ShopLineItem, ShopOrder } from '@mindfulchefuk/api-client'
import { spraypaintErrorMessage } from '@mindfulchefuk/utils/spraypaintUtils'
import {
  PendingLineItem,
  ShopOrder as ShopOrderType,
} from '@mindfulchefuk/query/ShopOrders/interfaces'
import errorHandler from '@mindfulchefuk/utils/errorHandler'
import { transformShopOrder } from '@mindfulchefuk/query/ShopOrders/transforms'
import { LINE_ITEMS } from '@mindfulchefuk/features/ShopOrder/constants'

type Params = {
  basketOrder: ShopOrderType
  lineItems: PendingLineItem[]
}

export const updateShopOrder = async ({
  basketOrder,
  lineItems,
}: Params): Promise<ShopOrderType> => {
  try {
    const apiOrder = new ShopOrder({ id: basketOrder.orderId })
    apiOrder.isPersisted = true
    const newLineItemsMap = new Map(
      lineItems.map((lineItem) => [lineItem.productId, lineItem])
    )

    const updatedLineItems = basketOrder.lineItems.map((basketLineItem) => {
      const apiLineItem = new ShopLineItem({
        product_id: basketLineItem.productId,
        id: basketLineItem.lineItemId,
      })

      apiLineItem.isPersisted = true

      // Get and then delete from the map because we want to get the remaining items later
      const newItem = newLineItemsMap.get(basketLineItem.productId)
      newLineItemsMap.delete(basketLineItem.productId)

      if (newItem) {
        apiLineItem.quantity = newItem.quantity

        return apiLineItem
      } else {
        apiLineItem.isMarkedForDestruction = true

        return apiLineItem
      }
    })

    // These are the remaining line items that we didn't delete earlier
    newLineItemsMap.forEach((lineItem) => {
      updatedLineItems.push(
        new ShopLineItem({
          product_id: lineItem.productId,
          quantity: lineItem.quantity,
        })
      )
    })

    apiOrder.line_items = updatedLineItems

    const results = await apiOrder.save({ with: LINE_ITEMS })

    if (!results) {
      throw new Error(spraypaintErrorMessage(apiOrder))
    }

    const order = transformShopOrder(apiOrder)

    // Remove line items with 0 quantity that are to be deleted
    order.lineItems = order.lineItems.filter(
      (orderLineItem) => !!orderLineItem.quantity
    )

    return order
  } catch (error) {
    if (Array.isArray(error)) {
      error.forEach((err) =>
        errorHandler(new Error(`error: ${err.title} - ${err.code}`), {
          suppress: true,
        })
      )
    } else {
      errorHandler(error, { suppress: true })
    }
  }
}
